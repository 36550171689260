import React from "react";
import { ThemeProvider } from "@mui/material";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";
import theme from "./lib/theme";
import RootNavigation from "./navigation";
import { AuthContextProvider } from "./components/authContext";
import "./assets/Imported/MyCustomCSS.css";
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: onClientError,
            retry: 2,
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError: onClientError,
            retry: 1,
            retryDelay: 1000,
        },
    },
});

function onClientError(error: unknown) {
    let errorMessage = "An error occurred, please try again";
    if ((error as AxiosError<{ message: string }>).response?.data?.message) {
        // @ts-ignore
        errorMessage = error.response.data.message;
    }

    enqueueSnackbar(errorMessage, {
        variant: "error",
    });
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <QueryClientProvider client={queryClient}>
                    <RootNavigation />
                </QueryClientProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
}

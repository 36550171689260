import React from "react";
import Loader from "../../assets/Imported/Loader";
import { isExpired } from "../../lib/role";
import { deleteData, retrieveData, saveData, StorageKeys } from "../../lib/storage";
import { Admin } from "../../lib/types";
import { RouteList } from "../../navigation/route";

interface AuthContextData {
    admin: Admin | null;
    doLogout: () => void;
    setAuthentication: (data: Admin, token: string) => void;
}

const defaultContextData = {
    admin: null,
    doLogout: () => {
        throw new Error("context not ready");
    },
    setAuthentication: () => {
        throw new Error("context not ready");
    },
};

export const authContext = React.createContext<AuthContextData>(defaultContextData);

interface AuthContextProps {
    children: React.ReactNode;
}

export function AuthContextProvider(props: AuthContextProps) {
    const [admin, setAdmin] = React.useState<Admin | null>(defaultContextData.admin);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        init();
    }, []);

    // authentication
    function setAuthentication(data: any, token: string) {
        setAdmin(data);
        saveData(StorageKeys.Token, token, true);
        saveData(StorageKeys.Admin, data);
        setLoading(false);
    }

    // logout
    function doLogout() {
        setAdmin(null);
        deleteData(StorageKeys.Token);
        deleteData(StorageKeys.Admin);
        window.location.replace(RouteList.HOME);
    }

    function init() {
        const savedAdminData = retrieveData<Admin>(StorageKeys.Admin);
        const savedToken = retrieveData<string>(StorageKeys.Token, true);
        if (savedToken && isExpired(savedToken)) {
            doLogout();
        }
        if (savedAdminData) {
            setAdmin(savedAdminData);
        }
        setLoading(false);
    }

    if (loading) {
        return <Loader title="Loading" />;
        // return <p>loading</p>;
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    return (
        <authContext.Provider
            value={{
                admin,
                setAuthentication,
                doLogout,
            }}
        >
            {props.children}
        </authContext.Provider>
    );
}

type LoaderProps = {
    title?: string;
};
export default function Loader({ title = "Loading" }: LoaderProps) {
    return (
        <div className="loader-component">
            <div className="loading">
                <h2 className="loader-title">{title}</h2>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            </div>
        </div>
    );
}

import { Language } from "./language";

export interface Admin {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    profileImage?: string | null;
}

export enum Gender {
    Male = "male",
    Female = "female",
    NonBinary = "non_binary",
}

interface Account {
    id: string;
    userId: string;
    accountNumber: string;
    accountName: string;
    sortCode: string;
    bank: string;
    isDefault: boolean;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
}

export interface User {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    country?: string;
    createdAt: string;
    deletedAt?: string;
    dob?: string;
    roleType?: "Guest" | "Agent";
    email: string;
    facebookId?: string;
    firstName: string;
    gender?: Gender;
    googleId?: string;
    id: string;
    isVerified: boolean;
    language: Language;
    lastName: string;
    phoneNumber?: string;
    state?: string;
    updatedAt: string;
    propertyNumber: number;
    bookings?: Booking[];
    properties?: Property[];
    accounts?: Account[];
}

export enum Currency {
    GBP = "GBP",
    USD = "USD",
    EUR = "EUR",
}

export enum BookingDurations {
    PerWeek = "Per Week",
    PerMonth = "Per Month",
    PerNight = "Per Night",
}

export enum PropertyType {
    House = "House",
    Apartment = "Apartment",
    PentHouse = "Pent House",
    Studio = "Studio",
    Bungalow = "Bungalow",
    Villa = "Villa",
    PrivateRoom = "Private Room",
    Cottage = "Cottage",
    SharedRoom = "Shared Room",
    OfficeSpace = "Office Space",
    Other = "Other",
}

export enum Role {
    Admin = "admin",
    Operations = "operations",
    SubAdmin = "subAdmin",
}

export enum StayType {
    ShortLet = "Short Let",
    HolidayLet = "Holiday Let",
}

export enum PropertyRelationship {
    Landlord = "Landlord",
    PropertyManager = "Property Manager",
    Agent = "Agent",
    LiveInLandlord = "Live in Landlord",
    Other = "Other",
}

export enum PropertySize {
    SquareFeet = "Square Feet",
    SquareMetres = "Square Metres",
}
export enum PropertyStatus {
    Pending = "Pending",
    Approved = "Approved",
    Declined = "Declined",
}

export enum PropertyPublishType {
    Publish = "Publish",
    UnPublish = "Un_Publish",
}

export interface Property {
    id: string;
    name: string;
    propertyType: PropertyType;
    stayType: StayType;
    propertyRelationship: PropertyRelationship;
    description?: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city: string;
    country: string;
    locationLatitude?: string;
    locationLongitude?: string;
    bedroom: number;
    bathroom: number;
    bedNumber: number;
    guest: number;
    minimumNumberNights: number;
    floor: string;
    featured?: boolean;
    propertySize?: number;
    featuredImage?: string;
    checkIn: string;
    checkOut: string;
    postCode: string;
    price: number;
    availableHolidayLet?: boolean;
    garden?: boolean;
    balcony?: boolean;
    terrace?: boolean;
    elevator?: boolean;
    mustReserve?: boolean;
    sofaBedAvailable?: boolean;
    bookingNumber?: number;
    propertySizeMetrics: PropertySize;
    user: User;
    currency: Currency;
    bookingDurations: BookingDurations;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
    bookings?: Booking[];
    status?: PropertyStatus;
    publishType: PropertyPublishType;
}
export interface Reservation {
    id: string;
    userId: string;
    propertyId: string;
    checkOut: string;
    checkIn: string;
    guest: number;
    note: string | null;
    status: "Canceled" | "Requested" | "Declined" | string;
    createdAt: string;
    updatedAt: string;
    property: Property;
    user: User;
}

export interface SubLedger {
    id: string;
    name: string;
    totalPrice: number;
    currency: Currency;
    entryType: string;
    ledgerId: string;
    feeRole: string;
    createdAt: string;
    updatedAt: string;
}
export interface Ledger {
    id: string;
    name: string;
    totalPrice: number;
    currency: Currency;
    entryType: string;
    stripeTransactionId: string;
    userId: string;
    bookingId: string;
    payoutId: string | null;
    createdAt: string;
    updatedAt: string;
    subLedgers: SubLedger[];
}
export interface LedgerResponse {
    message: string;
    data: Ledger;
    statusCode: number;
}

export interface LedgersResponse<Q> {
    message: string;
    data: {
        items: Ledger[];
        meta: {
            totalItems: number;
            itemCount: number;
            itemsPerPage: number;
            totalPages: number;
            currentPage: number;
        };
    };
    statusCode: number;
}

export enum BookingStatus {
    Booked = "Booked",
    CheckedIn = "Checked In",
    CheckedOut = "Checked Out",
    Canceled = "Canceled",
    CancellationRequested = "Cancellation Requested",
}

export interface Booking {
    id: string;
    userId: string;
    propertyId: string;
    ownerId: string;
    checkOut: string;
    checkIn: string;
    price: number;
    discountCode?: string;
    discountValue?: number;
    guest: number;
    fees: {
        name: string;
        value: number;
    }[];
    currency: Currency;
    transaction: Transaction;
    property: Property;
    status: BookingStatus;
    createdAt: Date;
    updatedAt: Date;
    user: User;
}

export enum TransactionStatus {
    Pending = "Pending",
    Completed = "Completed",
    Failed = "Failed",
}

export interface Transaction {
    id: string;
    price: number;
    email: string;
    transactionId: string;
    bookingId: string;
    currency: Currency;
    booking: Booking;
    customerRef: string;
    status: TransactionStatus;
    createdAt: string;
    updatedAt: Date;
}

export enum BookingCancellationStatus {
    Requested = "Requested",
    Completed = "Completed",
    Rejected = "Rejected",
    WaitingForRefund = "Waiting For Refund",
}

export interface BookingCancellation {
    bookingId: string;
    propertyId: string;
    userId: string;
    cancellationReason?: string;
    responseReason?: string;
    status: BookingCancellationStatus;
    booking: Booking;
    user: User;
    property: Property;
}

export interface Fees {
    id: string;
    name: string;
    description?: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
}
export interface AdminFees {
    id: string;
    name: string;
    value: number;
    feeRole: "User";
    feeType: "flat" | "percent" | null;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
}
export interface Restaurants {
    id: string;
    name: string;
    address: string;
    rating: string;
    image: string;
    discountTerms?: string;
    discountPromotion?: string;
}

export enum AmenityCategory {
    Features = "Features",
    Entertainment = "Entertainment",
    KitchenDining = "Kitchen and Dining",
    Facilities = "Facilities",
    Suitability = "Suitability",
    Other = "Other",
}

export interface Amenity {
    id: string;
    name: string;
    url: string;
    description?: string;
    amenityCategory: AmenityCategory;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
}

export interface Payout {
    id: string;
    userId?: string;
    adminId?: string;
    amountRequested: number;
    amountPaid: number;
    note: string;
    status: PayoutStatus;
    user: User;
    admin?: Admin;
    createdAt: Date;
    updatedAt: Date;
}

export enum PayoutStatus {
    Requested = "Requested",
    Completed = "Completed",
    Cancelled = "Cancelled",
}

export interface Discount {
    id: string;
    userId?: string;
    propertyId?: string;
    code: string;
    value: number;
    expiringAt?: string;
    user?: User;
    property?: Property;
    createdAt: Date;
    updatedAt: Date;
}

export interface Dashboard {
    totalProperties: number;
    properties: Property[];
    totalPayout: number;
    payouts: Payout[];
    totalUsers: number;
    users: User[];
    totalBooking: number;
    bookings: Booking[];
    transactions: Transaction[];
}

export enum Status {
    Open = "Open",
    Close = "Close",
}

export interface Support {
    id: string;
    userId: string;
    subject: string;
    status: Status;
    createdAt: string;
    updatedAt: string;
    user: User;
    inquiries: Inquiry[];
}

interface InquiryItem {
    id: string;
    userId: string;
    adminId: string;
    supportId: string;
    subject: string;
    note: string;
    status?: string;
    user: User;
    admin?: Admin;
    createdAt: string;
    updatedAt: string;
    support: Support;
}
export interface Inquiry {
    items: InquiryItem[];
    meansToAnEnd: number;
    id: string;
    userId: string;
    adminId: string;
    supportId: string;
    subject: string;
    note: string;
    status?: string;
    user: User;
    admin?: Admin;
    createdAt: string;
    updatedAt: string;
    support: Support;
}

export interface PriceColumn {
    row: {
        currency: "GBP" | "USD" | "AED" | "د.إ";
        price: number;
    };
}
export interface AmountRequestedColumn {
    row: {
        amountRequested: number;
    };
}
export interface AmountPaidColumn {
    row: {
        amountPaid: number;
    };
}

export interface FullNameColumn {
    row: {
        firstName: string;
        lastName: string;
    };
}
type CurrencyStrings = "GBP" | "USD" | "AED" | "د.إ" | string;
export const getCurrencySymbol = (currencyString: CurrencyStrings) => {
    switch (currencyString.toUpperCase()) {
        case "GBP":
            return "£";
            break;
        case "USD":
            return "$";
            break;
        case "EUR":
            return "€";
            break;
        case "AED":
            return "د.إ";
            break;
        default:
            return "";
    }
};

export interface FileUploadResponse {
    message: string;
    data: string;
    statusCode: number;
}

import { createTheme } from "@mui/material";

const baseButtonStyles = {
    padding: "12px 48px",
    fontWeight: "600",
    borderRadius: "8px",
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#003580",
        },
        secondary: {
            main: "#FFFFFF",
        },
        success: {
            main: "#2f9803",
        },
        action: {
            disabled: "#929497",
            disabledBackground: "rgba(146, 148, 151, 0.4)",
        },
    },

    // specify typography font as Be Vietnam Pro
    typography: {
        fontFamily: "Be Vietnam Pro",
    },
    
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#929497",
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "contained" },
                    style: {
                        "&:hover": {
                            backgroundColor: "#42C9F8",
                        },
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        "&:hover": {
                            backgroundColor: "#F7F7F7",
                        },
                    },
                },
                {
                    props: { variant: "text" },
                    style: {
                        "&:hover": {
                            backgroundColor: "#F7F7F7",
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    ...baseButtonStyles,
                    textTransform: "none",
                },
            },
        },
    },
});

export default theme;

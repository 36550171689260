import axios from "axios";
import Endpoints from "../endpoints";
import { StorageKeys } from "../storage";

export async function fetchData<Q>(url: string): Promise<Q> {
    const token = localStorage.getItem(StorageKeys.Token);
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    });
    const { data } = await axiosInstance<Q>({
        method: "GET",
        url,
    });
    return data;
}

export type APIMethods = "PUT" | "PATCH" | "DELETE" | "POST";

export async function mutateData<P, Q>({
    url,
    body,
    method = "POST",
    contentType,
}: {
    url: string;
    body?: P;
    method: APIMethods;
    contentType?: string;
}): Promise<Q> {
    const token = localStorage.getItem(StorageKeys.Token);
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Content-Type": contentType || "application/json",
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    });
    const { data } = await axiosInstance<Q>({
        method,
        url,
        data: body,
    });
    return data;
}

interface UploadFileParams {
    formData: FormData;
}
export async function UpdateFileToServer({ formData }: UploadFileParams) {
    const token = localStorage.getItem(StorageKeys.Token);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    return axios.request({
        method: "PATCH",
        url: `${baseURL ?? ""}${Endpoints.UpdateProfilePicture}`,
        headers: {
            Authorization: `Bearer ${token ?? ""}`,
            "Content-Type": "multipart/form-data",
        },
        maxBodyLength: Infinity,
        data: formData,
    });
}

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { navigations, operationsNavigations, privateNavigations } from "./route";
import { authContext } from "../components/authContext";
import BasePage from "../components/basePage";
import { Role } from "../lib/types";
import DashboardPage from "../pages/dashboard";
import Loader from "../assets/Imported/Loader";

// TODO: implement loading page
export default function RootNavigation() {
    const context = useContext(authContext);
    // eslint-disable-next-line no-nested-ternary
    const navigation = context.admin
        ? context.admin.role === Role.Operations
            ? operationsNavigations
            : privateNavigations
        : navigations;
    const Base = context.admin ? BasePage : React.Fragment;

    return (
        <Router>
            <Routes>
                {navigation.map((nav) => (
                    <Route
                        path={nav.path}
                        key={nav.path}
                        element={
                            <Base>
                                <Suspense fallback={<Loader title="Loading" />}>
                                    {/* <Suspense fallback={<p> Loading...</p>}> */}
                                    <nav.Component />
                                </Suspense>
                            </Base>
                        }
                    />
                ))}
                <Route
                    path="*"
                    element={
                        <Base>
                            {/* <Suspense fallback={<p> Loading...</p>}> */}
                            <Suspense fallback={<Loader title="Loading" />}>
                                <DashboardPage />
                            </Suspense>
                        </Base>
                    }
                />
                {/* <Route path="*" element={<Navigate to={navigation[0].path} replace />} /> */}
            </Routes>
        </Router>
    );
}

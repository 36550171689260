enum Endpoints {
    LOGIN = "/auth/admin/login",
    ForgotPassword = "/auth/admin/forgotPassword",
    ResetPassword = "/auth/admin/reset-password",
    GetUsers = "/user/all",
    GetUser = "/user/",
    ActivateUsers = "/user/activate/",
    DeactivateUsers = "/user/deactivate/",
    GetProperty = "/property/admin/",
    GetProperties = "/property/admin/all",
    ActivateProperty = "/property/admin/activate/",
    DeactivateProperty = "/property/admin/deactivate/",
    GetFees = "/fees/all",
    AddFees = "/fees/add",
    EditFees = "/fees/update/",
    DeactivateFees = "/fees/deactivate/",
    ActivateFees = "/fees/activate/",
    GetAdminFees = "/adminfees",
    AddAdminFees = "/adminfees",
    DeleteAdminFee = "/adminfees/",
    GetAmenity = "/amenity/all",
    AddAmenity = "/amenity/add",
    EditAmenity = "/amenity/update/",
    DeactivateAmenity = "/amenity/deactivate/",
    ActivateAmenity = "/amenity/activate/",
    GetTransactions = "/booking/transaction",
    GetSingleTransaction = "/booking/transaction/",
    GetBooking = "/booking/all",
    GetBookingCancellations = "/booking/admin/cancellations",
    AddRestaurant = "/restaurant/new",
    GetRestaurants = "/restaurant",
    EditRestaurant = "/restaurant/new/",
    DeactivateRestaurant = "/restaurant/deactivate/",
    ActivateRestaurant = "/restaurant/activate/",
    GetPayout = "/payout/all",
    EditPayout = "/payout/",
    GetDiscount = "/discount/all",
    AddDiscount = "/discount/add",
    ExpireDiscount = "/discount/",
    GetAdmin = "/admin/all",
    AddAdmin = "/admin/add",
    ActivateAdmin = "/admin/activate/",
    DeactivateAdmin = "/admin/deactivate/",
    EditAdmin = "/admin/update/",
    GetSupports = "/support/all",
    GetSupport = "/support/admin/",
    UpdateSupport = "/support/update/",
    GetDashboard = "/dashboard/",
    GetAllReservations = "/property/admin/reservations",
    GetSingleReservation = "/property/admin/reservation/",
    GetLedgers = "/payout/ledgers",
    GetSingleLedger = "/payout/ledger/",
    TurnOnFeaturedProperty = "/property/admin/onFeatured/",
    TurnOffFeaturedProperty = "/property/admin/offFeatured/",

    UpdateProfilePicture = "/admin/picture",
    SetUserRoleType = "/user/setrole",
}

export default Endpoints;

import {GridColDef} from "@mui/x-data-grid";
import * as jose from 'jose'
import { DateTime } from "luxon";
import {Role} from "./types";

export function isSubAdmin(role?: Role) {
    if (!role)  {
        return false;
    }
    return role === Role.SubAdmin;
}

export function isOperationAdmin(role?: Role) {
    if (!role)  {
        return false;
    }
    return role === Role.Operations;
}

export function getTableActions(option: any, role?: Role): [GridColDef] {
    if (isSubAdmin(role)) {
        // @ts-ignore
        return [];
    }

    return [option];
}

export function isExpired (token: string): boolean {
    try {
        const resp = jose.decodeJwt(token);
        if (!resp.exp) {
            return false;
        }
        return DateTime.fromMillis(resp.exp * 1000) < DateTime.now();
    } catch (e) {
        return false;
    }
}

import { lazy } from "react";
import {
    Dashboard,
    Group,
    HolidayVillage,
    Event,
    StackedBarChart,
    Payment,
    Deck,
    AccountBalanceWallet,
    Discount,
    AdminPanelSettings,
    EventBusy,
    HelpCenter,
    Restaurant,
    SvgIconComponent,
    SupportAgent,
    Bookmarks,
    Topic,
    AccountCircle,
} from "@mui/icons-material";

export enum RouteList {
    HOME = "/",
    Login = "/login",
    FORGOTPASSWORD = "/forgot",
    RESETPASSWORD = "/reset",
    DASHBOARD = "/dashboard",
    USERS = "/users",
    VIEWUSER = "/users/:userId",
    VIEWPROPERTY = "/property/:propertyId",
    BOOKING = "/bookings",
    VIEWBOOKING = "/bookings/:bookingId",
    BOOKINGCANCELLATIONS = "/cancellations",
    PROPERTY = "/property",
    TRANSACTION = "/transactions",
    VIEWTRANSACTION = "/transactions/:transactionID",
    FEE = "/fees",
    AMENITY = "/amenity",
    PAYOUT = "/payout",
    DISCOUNT = "/discount",
    Admin = "/admin",
    HOSPITALITY = "/hospitality",
    RESERVATION = "/reservations",
    VIEWRESERVATION = "/reservations/:reservationID",
    SUPPORT = "/support",
    VIEWSUPPORT = "/support/:supportId",
    LEDGERS = "/ledgers",
    VIEWLEDGERS = "/ledgers/:ledgerID",
    PROFILE = "/profile",
}

export const navigations = [
    {
        path: RouteList.HOME,
        Component: lazy(() => import("../pages/login")),
    },
    {
        path: RouteList.Login,
        Component: lazy(() => import("../pages/login")),
    },
    {
        path: RouteList.FORGOTPASSWORD,
        Component: lazy(() => import("../pages/forgotPassword")),
    },
    {
        path: RouteList.RESETPASSWORD,
        Component: lazy(() => import("../pages/reset")),
    },
];

export const operationsNavigations = [
    {
        path: RouteList.PAYOUT,
        Component: lazy(() => import("../pages/payout")),
    },
    {
        path: RouteList.TRANSACTION,
        Component: lazy(() => import("../pages/transaction")),
    },
];

export const privateNavigations = [
    {
        path: RouteList.DASHBOARD,
        Component: lazy(() => import("../pages/dashboard")),
    },
    {
        path: RouteList.HOME,
        Component: lazy(() => import("../pages/dashboard")),
    },
    {
        path: RouteList.USERS,
        Component: lazy(() => import("../pages/users")),
    },
    {
        path: RouteList.BOOKING,
        Component: lazy(() => import("../pages/booking")),
    },
    {
        path: RouteList.VIEWBOOKING,
        Component: lazy(() => import("../pages/viewBooking")),
    },
    {
        path: RouteList.PROPERTY,
        Component: lazy(() => import("../pages/property")),
    },
    {
        path: RouteList.TRANSACTION,
        Component: lazy(() => import("../pages/transaction")),
    },
    {
        path: RouteList.VIEWTRANSACTION,
        Component: lazy(() => import("../pages/viewTransaction")),
    },
    {
        path: RouteList.FEE,
        Component: lazy(() => import("../pages/fees")),
    },
    {
        path: RouteList.HOSPITALITY,
        Component: lazy(() => import("../pages/hospitality")),
    },
    {
        path: RouteList.RESERVATION,
        Component: lazy(() => import("../pages/reservation")),
    },
    {
        path: RouteList.VIEWRESERVATION,
        Component: lazy(() => import("../pages/viewReservation")),
    },
    {
        path: RouteList.LEDGERS,
        Component: lazy(() => import("../pages/ledgers")),
    },
    {
        path: RouteList.VIEWLEDGERS,
        Component: lazy(() => import("../pages/viewLedger")),
    },
    {
        path: RouteList.AMENITY,
        Component: lazy(() => import("../pages/amenity")),
    },
    {
        path: RouteList.VIEWUSER,
        Component: lazy(() => import("../pages/viewUser")),
    },
    {
        path: RouteList.VIEWPROPERTY,
        Component: lazy(() => import("../pages/viewProperty")),
    },
    {
        path: RouteList.BOOKINGCANCELLATIONS,
        Component: lazy(() => import("../pages/bookingCancellation")),
    },
    {
        path: RouteList.PAYOUT,
        Component: lazy(() => import("../pages/payout")),
    },
    {
        path: RouteList.DISCOUNT,
        Component: lazy(() => import("../pages/discount")),
    },
    {
        path: RouteList.Admin,
        Component: lazy(() => import("../pages/admin")),
    },
    {
        path: RouteList.SUPPORT,
        Component: lazy(() => import("../pages/support")),
    },
    {
        path: RouteList.PROFILE,
        Component: lazy(() => import("../pages/profile")),
    },
    {
        path: RouteList.VIEWSUPPORT,
        Component: lazy(() => import("../pages/viewSupport")),
    },
];

export type MenuObjectType = {
    Icon: SvgIconComponent | string;
    IconType?: string;
    path: string;
    title: string;
};

export const operationsMenu: MenuObjectType[] = [
    {
        Icon: StackedBarChart,
        path: RouteList.TRANSACTION,
        title: "Transactions",
    },
    {
        Icon: AccountBalanceWallet,
        path: RouteList.PAYOUT,
        title: "Payout",
    },
];

export const menu: MenuObjectType[] = [
    {
        Icon: Dashboard,
        path: RouteList.DASHBOARD,
        title: "Dashboard",
    },
    {
        Icon: Group,
        path: RouteList.USERS,
        title: "Users",
    },
    {
        Icon: Event,
        path: RouteList.BOOKING,
        title: "Bookings",
    },
    {
        Icon: EventBusy,
        path: RouteList.BOOKINGCANCELLATIONS,
        title: "Cancellations",
    },
    {
        Icon: HolidayVillage,
        path: RouteList.PROPERTY,
        title: "Properties",
    },
    {
        Icon: Discount,
        path: RouteList.HOSPITALITY,
        title: "Hospitality",
    },
    {
        Icon: Bookmarks,
        path: RouteList.RESERVATION,
        title: "Reservations",
    },
    {
        Icon: Topic,
        path: RouteList.LEDGERS,
        title: "Ledgers",
    },
    {
        Icon: Payment,
        path: RouteList.FEE,
        title: "Fees",
    },
    {
        Icon: Deck,
        path: RouteList.AMENITY,
        title: "Amenity",
    },
    {
        Icon: AdminPanelSettings,
        path: RouteList.Admin,
        title: "Admin",
    },
    {
        Icon: StackedBarChart,
        path: RouteList.TRANSACTION,
        title: "Transactions",
    },
    {
        Icon: AccountBalanceWallet,
        path: RouteList.PAYOUT,
        title: "Payout",
    },
    {
        Icon: "iconamoon:discount-thin",
        IconType: "iconify",
        path: RouteList.DISCOUNT,
        title: "Discount",
    },
    {
        Icon: SupportAgent,
        path: RouteList.SUPPORT,
        title: "Support",
    },
    {
        Icon: AccountCircle,
        path: RouteList.PROFILE,
        title: "Profile",
    },
];

const GuestGroup = ["Bookings", "Cancellations", "Reservations"];
const SettingsGroup = ["Fees", "Amenity", "Admin"];
const PaymentsGroup = ["Transactions", "Payout", "Ledgers"];

const AllGroups = [...GuestGroup, ...SettingsGroup, ...PaymentsGroup];

const getMenuItem = (menuString: string) => {
    let menuObject: MenuObjectType = {
        Icon: Event,
        IconType: "",
        path: "",
        title: "",
    };
    switch (menuString) {
        case "Bookings":
            menuObject = {
                Icon: Event,
                path: RouteList.BOOKING,
                title: "Bookings",
            };
            break;
        case "Cancellations":
            menuObject = {
                Icon: EventBusy,
                path: RouteList.BOOKINGCANCELLATIONS,
                title: "Cancellations",
            };
            break;
        case "Reservations":
            menuObject = {
                Icon: Bookmarks,
                path: RouteList.RESERVATION,
                title: "Reservations",
            };

            break;
        case "Fees":
            menuObject = {
                Icon: Payment,
                path: RouteList.FEE,
                title: "Fees",
            };
            break;
        case "Amenity":
            menuObject = {
                Icon: Deck,
                path: RouteList.AMENITY,
                title: "Amenity",
            };
            break;
        case "Admin":
            menuObject = {
                Icon: AdminPanelSettings,
                path: RouteList.Admin,
                title: "Admin",
            };
            break;
        case "Transactions":
            menuObject = {
                Icon: StackedBarChart,
                path: RouteList.TRANSACTION,
                title: "Transactions",
            };
            break;
        case "Payout":
            menuObject = {
                Icon: AccountBalanceWallet,
                path: RouteList.PAYOUT,
                title: "Payout",
            };
            break;
        case "Ledgers":
            menuObject = {
                Icon: Topic,
                path: RouteList.LEDGERS,
                title: "Ledgers",
            };
            break;
        default:
            menuObject = {
                ...menuObject,
            };
    }
    return menuObject;
};

export { GuestGroup, SettingsGroup, PaymentsGroup, AllGroups, getMenuItem };

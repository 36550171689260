import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { FiberManualRecord, Launch, LocationOn } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import PaymentMethodIcon from "../../assets/icons/payment-method.svg";
import UsersIcon from "../../assets/icons/users.svg";
import DateIcon from "../../assets/icons/date.svg";
import { performQuery, NonPaginatedResponse } from "../../lib/request/useQuery";
import { Dashboard, getCurrencySymbol, Property } from "../../lib/types";
import Endpoints from "../../lib/endpoints";
import { getAddress } from "../../lib/address";
import { RouteList } from "../../navigation/route";

import "../../assets/Imported/MyCustomCSS.css";
import Loader from "../../assets/Imported/Loader";
import { getIsEmpty } from "../../components/basePage";

export default function DashboardPage() {
    const { data: response, isLoading } = useQuery<
        unknown,
        unknown,
        NonPaginatedResponse<Dashboard>
    >(["getDiscount"], () => performQuery<Dashboard>(Endpoints.GetDashboard));
    const navigate = useNavigate();

    function goTo(route: RouteList) {
        return () => navigate(route);
    }

    if (isLoading || !response) {
        return <Loader title="Loading" />;
    }

    const { data } = response;
   //  console.log(data);
    const getRecentProperties = () => {
        return data?.properties.slice(-2).reverse();
    };
    return (
        <Box sx={{ padding: "32px", display: "flex" }} className="dashboard-box-container">
            {/* {data?.totalBooking && ( */}
                <>
                    <Box
                        sx={{ marginRight: "11px", flex: "0.66" }}
                        className="dashboard-box-container-left flex-column"
                    >
                        <Box
                            sx={{
                                backgroundColor: "#FAFAFA",
                                borderRadius: "20px",
                                padding: "24px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: "20px",
                                    marginBottom: "20px",
                                    borderBottom: "2px solid #D9D9D9",
                                }}
                            >
                                <Typography variant="h6">Recent Properties</Typography>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                        Number of Properties:
                                    </Typography>
                                    <Paper
                                        sx={{
                                            padding: "8px 16px",
                                            border: "1px solid #D9D9D9",
                                            marginLeft: "8px",
                                        }}
                                        elevation={0}
                                    >
                                        {`${data?.totalProperties ? data?.totalProperties : 0} Properties`}
                                    </Paper>
                                </Box>
                            </Box>
                            <div className="featured-properties flex-row">
                                {getRecentProperties().map((property) => {
                                    const propertyBaseURL =
                                        "https://quickstay-dev.s3.eu-west-2.amazonaws.com/";
                                    const {
                                        addressLine1,
                                        addressLine2,
                                        addressLine3,
                                        name: propertyName,
                                        featuredImage,
                                        price,
                                        currency,
                                        bedroom,
                                        propertyType,
                                        user,
                                        id,
                                        propertyRelationship,
                                    } = property;

                                    const { firstName, lastName } = user;
                                    const getPropertyLocation = () => {
                                        return `${addressLine1.toString()} ${
                                            addressLine2?.toString() || ""
                                        } ${addressLine3?.toString() || ""}.`;
                                    };
                                    const getPropertyLink = () => {
                                        const baseURL = process.env.WEB_BASE_URL ? process.env.WEB_BASE_URL : "https://dev.quickstay.online";
                                        return `${baseURL}/property/${id}`;
                                    };

                                    return (
                                        <div className="featured-property flex-column" key={id}>
                                            <img
                                                src={`${propertyBaseURL}${
                                                    featuredImage?.toString() || ""
                                                }`}
                                                alt=""
                                                className="featured-property-image"
                                            />
                                            <div className="featured-property-about flex-column">
                                                <span className="featured-property-name">
                                                    {propertyName}
                                                </span>
                                                <span className="featured-property-location flex-row">
                                                    <i className="fas fa-map-marker-alt" />
                                                    &nbsp; {getPropertyLocation()}
                                                </span>
                                            </div>
                                            <div className="featured-property-details flex-column">
                                                <div className="featured-property-detail flex-row">
                                                    <span className="featured-property-detail-title">
                                                        Price
                                                    </span>
                                                    <span className="featured-property-detail-value">
                                                        {getCurrencySymbol(currency.toUpperCase())}{" "}
                                                        {price.toFixed(2)}
                                                    </span>
                                                </div>
                                                <div className="featured-property-detail flex-row">
                                                    <span className="featured-property-detail-title">
                                                        Bedroom
                                                    </span>
                                                    <span className="featured-property-detail-value">
                                                        {bedroom}
                                                    </span>
                                                </div>
                                                <div className="featured-property-detail flex-row">
                                                    <span className="featured-property-detail-title">
                                                        Type
                                                    </span>
                                                    <span className="featured-property-detail-value">
                                                        {propertyType}
                                                    </span>
                                                </div>
                                                <Box sx={{ marginTop: "20px" }}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{ fontWeight: "700" }}
                                                        color="primary"
                                                    >
                                                        Property Owner
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            marginTop: "8px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Avatar sx={{ width: 50, height: 50 }}>
                                                            {`${
                                                                property.user.firstName
                                                                    ? property.user.firstName.charAt(
                                                                          0,
                                                                      )
                                                                    : ""
                                                            }${
                                                                property.user.lastName
                                                                    ? property.user.lastName.charAt(
                                                                          0,
                                                                      )
                                                                    : ""
                                                            }`}
                                                        </Avatar>
                                                        <Box sx={{ marginLeft: "8px" }}>
                                                            <Typography
                                                                variant="caption"
                                                                color="primary"
                                                                component="h6"
                                                            >
                                                                {property.user.firstName}
                                                            </Typography>
                                                            <Typography
                                                                variant="caption"
                                                                color="grey"
                                                                component="p"
                                                            >
                                                                {property.propertyRelationship}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <div className="featured-property-button-container flex-row">
                                                    <a
                                                        href={getPropertyLink()}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="featured-property-button flex-row center-text"
                                                    >
                                                        View Listing
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: "#FAFAFA",
                                borderRadius: "20px",
                                padding: "24px",
                                marginTop: "20px",
                            }}
                        >
                            <Typography variant="h6">Bookings</Typography>
                            <TableContainer sx={{ marginTop: "30px" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    borderTop: "1px solid #D9D9D9",
                                                    borderBottom: "1px solid #D9D9D9",
                                                }}
                                            >
                                                Listing
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderTop: "1px solid #D9D9D9",
                                                    borderBottom: "1px solid #D9D9D9",
                                                }}
                                            >
                                                Guest
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderTop: "1px solid #D9D9D9",
                                                    borderBottom: "1px solid #D9D9D9",
                                                }}
                                            >
                                                Check-In
                                            </TableCell>
                                            {/* <TableCell sx={{ borderTop: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9" }}>Transaction ref.</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9" }}>Customer ref.</TableCell> */}
                                            <TableCell
                                                sx={{
                                                    borderTop: "1px solid #D9D9D9",
                                                    borderBottom: "1px solid #D9D9D9",
                                                }}
                                            >
                                                Check-Out
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.bookings.map((booking) => (
                                            <TableRow>
                                                <TableCell sx={{ padding: "16px 8px" }}>
                                                    <Typography variant="caption">
                                                        {booking.property.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: "16px 8px" }}>
                                                    {`${booking.user.firstName} ${booking.user.lastName}`}
                                                </TableCell>
                                                <TableCell sx={{ padding: "16px 8px" }}>
                                                    <Typography variant="caption">
                                                        {DateTime.fromISO(booking.checkIn).toFormat(
                                                            "LLL d, y",
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                {/* <TableCell sx={{ padding: "16px 8px", wordBreak: "break-word" }}>
                                            <Typography variant="caption">{transaction.transactionId}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: "16px 8px" }}>
                                            <Typography variant="caption">{transaction.customerRef}</Typography>
                                        </TableCell> */}
                                                <TableCell sx={{ padding: "16px 8px" }}>
                                                    <Typography variant="caption" color="success">
                                                        {DateTime.fromISO(
                                                            booking.checkOut,
                                                        ).toFormat("LLL d, y")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box
                        sx={{ marginLeft: "11px", flex: "0.34" }}
                        className="dashboard-box-container-right"
                    >
                        <Paper
                            className="total-payout-box"
                            elevation={0}
                            sx={{
                                // background: "linear-gradient(180deg, #42C9F8 0%, #003580 100%)",
                                backgroundColor: "#1e3674",
                                borderRadius: "20px",
                                paddingTop: "32px",
                                marginBottom: "16px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <img
                                    src={PaymentMethodIcon}
                                    alt="payment_method"
                                    style={{ height: "50px", width: "50px" }}
                                />
                                <Typography
                                    color="white"
                                    sx={{ fontSize: "16px", fontWeight: "500", marginTop: "8px" }}
                                >
                                    Total Payout
                                </Typography>
                                <Typography
                                    color="white"
                                    sx={{ fontSize: "30px", fontWeight: "500" }}
                                >
                                    £
                                    {data?.totalPayout
                                        ? data?.totalPayout.toLocaleString(undefined, {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                          })
                                        : 0}
                                </Typography>
                            </Box>
                            <TableContainer
                                className="dashboard-payout-table"
                                sx={{ borderBottom: "1px solid white" }}
                            >
                                <Table>
                                    <TableBody>
                                        {data?.payouts && data?.payouts.length > 0 ? data.payouts.map((payouts) => {
                                            let account = {
                                                accountName: "Not Set",
                                                accountNumber: "Not set",
                                            };
                                            if (payouts.user.accounts?.length) {
                                                const defaultAccount = payouts.user.accounts.find(
                                                    (acc) => acc.isDefault,
                                                );
                                                account =
                                                    defaultAccount ?? payouts.user.accounts[0];
                                            }
                                            return (
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexDirection: "row",
                                                            borderBottom: "none",
                                                            paddingBottom: "8px",
                                                            paddingTop: "8px",
                                                        }}
                                                        color="white"
                                                    >
                                                        {/* <FiberManualRecord
                                                            fontSize="small"
                                                            color="success"
                                                        /> */}
                                                        <Typography color="white" component="span">
                                                            {account.accountName}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            borderBottom: "none",
                                                            paddingBottom: "8px",
                                                            paddingTop: "8px",
                                                        }}
                                                    >
                                                        <Typography color="white">
                                                            {account.accountNumber}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            borderBottom: "none",
                                                            paddingBottom: "8px",
                                                            paddingTop: "8px",
                                                        }}
                                                    >
                                                        <Typography color="white">
                                                            £
                                                            {payouts.amountRequested.toLocaleString(
                                                                undefined,
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                },
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }) : ''}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <button
                                    type="button"
                                    onClick={goTo(RouteList.PAYOUT)}
                                    className="dashboard-link flex-row"
                                >
                                    See Payouts&nbsp; <i className="far fa-external-link-alt" />
                                </button>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            className="total-users-box"
                            sx={{
                                background:
                                    "linear-gradient(180deg, rgba(217, 217, 217, 0.3) 0%, rgba(217, 217, 217, 0.051) 100%)",
                                borderRadius: "20px",
                                paddingTop: "32px",
                                marginBottom: "16px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <img
                                    src={UsersIcon}
                                    alt="payment_method"
                                    style={{ height: "50px", width: "50px" }}
                                />
                                <Typography
                                    color="primary"
                                    sx={{ fontSize: "16px", fontWeight: "500", marginTop: "8px" }}
                                >
                                    Total Users
                                </Typography>
                                <Typography
                                    color="primary"
                                    sx={{ fontSize: "30px", fontWeight: "500" }}
                                >
                                    {data.totalUsers}
                                </Typography>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #727171" }}>
                                {data.users.map((user) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            borderBottom: "1px solid #979797",
                                            padding: "8px",
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                marginRight: "20px",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {`${user.firstName ? user.firstName.charAt(0) : ""}${
                                                user.lastName ? user.lastName.charAt(0) : ""
                                            }`}
                                        </Avatar>
                                        <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                                            {`${user.firstName} ${user.lastName}`}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    color="primary"
                                    endIcon={<Launch color="primary" />}
                                    onClick={goTo(RouteList.USERS)}
                                >
                                    see users
                                </Button>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                background:
                                    "linear-gradient(180deg, rgba(217, 217, 217, 0.3) 0%, rgba(217, 217, 217, 0.051) 100%)",
                                borderRadius: "20px",
                                marginBottom: "16px",
                            }}
                            className="transaction-history"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    paddingTop: "32px",
                                    paddingBottom: "16px",
                                    borderTopRightRadius: "20px",
                                    borderTopLeftRadius: "20px",
                                    background:
                                        "linear-gradient(180deg, rgba(0, 53, 128, 0.5) 0%, rgba(114, 113, 113, 0.5) 100%)",
                                }}
                            >
                                <img
                                    src={DateIcon}
                                    alt="payment_method"
                                    style={{ height: "50px", width: "50px" }}
                                />
                                <Typography
                                    color="white"
                                    sx={{ fontSize: "16px", fontWeight: "500", marginTop: "8px" }}
                                >
                                    Transaction History
                                </Typography>
                                <Typography
                                    color="white"
                                    sx={{ fontSize: "30px", fontWeight: "500" }}
                                >
                                    {data.transactions.length}
                                </Typography>
                            </Box>
                            <TableContainer sx={{ borderBottom: "1px solid #003580" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: "1px solid #003580" }}>
                                                Date
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "1px solid #003580" }}>
                                                Provider
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "1px solid #003580" }}>
                                                Amount
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "1px solid #003580" }}>
                                                Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.transactions.map((transaction) => (
                                            <TableRow>
                                                <TableCell
                                                    sx={{ borderBottom: "none", padding: "8px" }}
                                                >
                                                    <Typography variant="caption">
                                                        {DateTime.fromISO(
                                                            transaction.createdAt,
                                                        ).toFormat("LLL d, y")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    sx={{ borderBottom: "none", padding: "8px" }}
                                                >
                                                    <Typography variant="caption">
                                                        Stripe
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    sx={{ borderBottom: "none", padding: "8px" }}
                                                >
                                                    <Typography variant="caption">
                                                        {`${getCurrencySymbol(
                                                            transaction.currency.toUpperCase(),
                                                        )} ${transaction.price.toFixed(2)}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    sx={{ borderBottom: "none", padding: "8px" }}
                                                >
                                                    <Typography variant="caption">
                                                        {transaction.status}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    color="primary"
                                    endIcon={<Launch color="primary" />}
                                    onClick={goTo(RouteList.TRANSACTION)}
                                >
                                    See Transactions
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </>
            {/* )} */}
        </Box>
    );
}

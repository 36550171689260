import { Property, Reservation, User } from "../types";
import { fetchData } from "./api";

export interface Response<Q> {
    statusCode: number;
    message: string;
    data: {
        items: Q;
        meta: {
            totalItems: number;
            totalPages: number;
        };
    };
}
export interface RestaurantResponse<Q> {
    statusCode: number;
    message: string;
    data: {
        items: [];
    };
}
export interface AdminFeesResponse<Q> {
    statusCode: number;
    message: string;
    data: {
        items: Q;
        meta: {
            totalItems: number;
            itemCount: number;
            itemsPerPage: number;
            totalPages: number;
            currentPage: number;
        };
    };
}
export interface ReservationsResponse<Q> {
    statusCode: number;
    message: string;
    data: {
        items: Reservation[];
        meta: {
            totalItems: number;
            itemCount: number;
            itemsPerPage: number;
            totalPages: number;
            currentPage: number;
        };
    };
}
export interface SingleReservationResponse<Q> {
    statusCode: number;
    message: string;
    data: Reservation;
}

export interface NonPaginatedResponse<Q> {
    statusCode: number;
    message: string;
    data: Q;
}

export async function performQuery<Q>(endpoint: string): Promise<Response<Q>> {
    return fetchData<Response<Q>>(endpoint);
}

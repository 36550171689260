import React, { ReactNode, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
    Dashboard,
    Logout,
    ExpandMore,
    Group,
    HolidayVillage,
    Event,
    StackedBarChart,
    Payment,
    Deck,
    AccountBalanceWallet,
    Discount,
    AdminPanelSettings,
    EventBusy,
    HelpCenter,
    Restaurant,
    Settings,
    Payments,
} from "@mui/icons-material";
import {
    Typography,
    Toolbar,
    List,
    AppBar,
    ListItem,
    ListItemButton,
    Box,
    ListItemIcon,
    ListItemText,
    Avatar,
} from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";

import {
    AllGroups,
    GuestGroup,
    SettingsGroup,
    PaymentsGroup,
    menu,
    operationsMenu,
    RouteList,
    getMenuItem,
} from "../../navigation/route";
import { authContext } from "../authContext";
import { Role } from "../../lib/types";
import NavLogo from "../../assets/Imported/LogoNavNew.png";

import SettingsIcon from "../../assets/Imported/SettingsIcon.png";
import GuestIcon from "../../assets/Imported/GuestIcon.png";
import PaymentIcon from "../../assets/Imported/PaymentIcon.png";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const useStyles = makeStyles({
    paper: {
        background: "#003580 !important",
        // @ts-ignore
        position: "initial !important",
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(() => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    backgroundColor: "#003580",
    overflowX: "hidden",
}));

interface BasePageProps {
    children: ReactNode;
}
const getIsEmpty = (property: any) => {
    if (property) {
        return property;
    }
    return "";
};

export default function BasePage(props: BasePageProps) {
    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const classes = useStyles();
    const context = React.useContext(authContext);
    // eslint-disable-next-line no-nested-ternary
    const navMenu = context.admin!.role === Role.Operations ? operationsMenu : menu;

    const [isHeaderMenuShowing, setHeaderMenuShowing] = useState(false);

    useEffect(() => {
        document.body.addEventListener("click", (e) => {
            const target = e.target as Element;
            const { classList } = target;
            const isMenuIcon = classList.contains("toggle-header-menu-icon");
            if (!isMenuIcon) {
                setHeaderMenuShowing(false);
            }
            // Check if any parent element has the class name
            // let parent = target.parentElement;
            // while (parent) {
            //     if (parent.classList.contains("menu-prevent-close")) {
            //         return;
            //     }
            //     parent = parent.parentElement;
            // }
            // setGuestsMenuVisible(false);
            // setSettingsMenuVisible(false);
            // setPaymentsMenuVisible(false);
        });
    }, []);
    useEffect(() => {
        const getPathOrPathParent = (path: string) => {
            const lastIndex = path.lastIndexOf("/");

            let val: string = "";
            if (lastIndex !== 0) {
                // Current Path is a subpath e.g /bookings/adsfhadofiha89312eh9
                val = path.substring(0, lastIndex);
            } else {
                // Current Path is a single path e.g /users or /transactions
                val = path;
            }
            val = val.replaceAll("/", "").toLowerCase();
            return val;
        };
        const { pathname } = location;
        const val = getPathOrPathParent(pathname);
        console.log("Current Path or Path parent", val);
        if (isSettingsMenuVisible) {
            const isSettingLink = SettingsGroup.filter((s) => s.toLowerCase() === val);
            if (isSettingLink.length === 0) {
                setSettingsMenuVisible(false);
            }
        }
        if (isGuestsMenuVisible) {
            const isGuestLink = GuestGroup.filter((g) => g.toLowerCase() === val);
            if (isGuestLink.length === 0) {
                setGuestsMenuVisible(false);
            }
        }
        if (isPaymentsMenuVisible) {
            const isPaymentLink = PaymentsGroup.filter((p) => p.toLowerCase() === val);
            if (isPaymentLink.length === 0) {
                setPaymentsMenuVisible(false);
            }
        }
    }, [location]);

    const [isGuestsMenuVisible, setGuestsMenuVisible] = useState<boolean>(false);
    const [isSettingsMenuVisible, setSettingsMenuVisible] = useState<boolean>(false);
    const [isPaymentsMenuVisible, setPaymentsMenuVisible] = useState<boolean>(false);

    return (
        <Box sx={{ display: "-webkit-box", minHeight: "100vh" }}>
            <div className="full-height-nav-ghost" />
            <div className="full-height-nav-bg">
                <Drawer
                    variant="permanent"
                    anchor="left"
                    classes={{ paper: classes.paper }}
                    style={{
                        zIndex: 99999,
                    }}
                >
                    <DrawerHeader />
                    <List>
                        {navMenu
                            .filter((item) => !AllGroups.includes(item.title))
                            .filter((item) => item.title === "Dashboard" || item.title === "Users")
                            .map((menuItem) => {
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    <menuItem.Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}

                        {/* Guest Group */}
                        <span className="menu-prevent-close prevent-guest-close">
                            <ListItem
                                disablePadding
                                sx={{ display: "block" }}
                                color="secondary"
                                onClick={() => {
                                    setGuestsMenuVisible(!isGuestsMenuVisible);
                                    setSettingsMenuVisible(false);
                                    setPaymentsMenuVisible(false);
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        px: 2.5,
                                        color: "#FFFFFF",
                                        flexDirection: "column",
                                    }}
                                >
                                    <img src={GuestIcon} className="sidebar-icon" alt="" />
                                    <ListItemText primaryTypographyProps={{ variant: "caption" }}>
                                        Guests &nbsp;{" "}
                                        <i
                                            className={`far fa-chevron-${
                                                isGuestsMenuVisible ? "up" : "down"
                                            }`}
                                        />
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </span>
                        <motion.div
                            initial={false}
                            animate={{
                                height: isGuestsMenuVisible ? "fit-content" : 0,
                                overflow: isGuestsMenuVisible ? "clip" : "clip",
                            }}
                        >
                            {GuestGroup.map((item) => {
                                const menuItem = getMenuItem(item);
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                            style={{
                                                backgroundColor: "#42C9F8",
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    <menuItem.Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </motion.div>

                        {navMenu
                            .filter((item) => !AllGroups.includes(item.title))
                            .filter(
                                (item) =>
                                    item.title === "Properties" || item.title === "Hospitality",
                            )
                            .map((menuItem) => {
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    <menuItem.Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}

                        {/* Settings Group */}
                        <span className="menu-prevent-close prevent-settings-close">
                            <ListItem
                                disablePadding
                                sx={{ display: "block" }}
                                color="secondary"
                                onClick={() => {
                                    setSettingsMenuVisible(!isSettingsMenuVisible);
                                    setGuestsMenuVisible(false);
                                    setPaymentsMenuVisible(false);
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        px: 2.5,
                                        color: "#FFFFFF",
                                        flexDirection: "column",
                                    }}
                                >
                                    <img src={SettingsIcon} className="sidebar-icon" alt="" />
                                    <ListItemText primaryTypographyProps={{ variant: "caption" }}>
                                        Settings &nbsp;{" "}
                                        <i
                                            className={`far fa-chevron-${
                                                isSettingsMenuVisible ? "up" : "down"
                                            }`}
                                        />
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </span>
                        <motion.div
                            initial={false}
                            animate={{
                                height: isSettingsMenuVisible ? "fit-content" : 0,
                                overflow: isSettingsMenuVisible ? "clip" : "clip",
                            }}
                        >
                            {SettingsGroup.map((item) => {
                                const menuItem = getMenuItem(item);
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                            style={{
                                                backgroundColor: "#42C9F8",
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    <menuItem.Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </motion.div>

                        {/* Payments Group */}
                        <span className="menu-prevent-close prevent-payments-close">
                            <ListItem
                                disablePadding
                                sx={{ display: "block" }}
                                color="secondary"
                                onClick={() => {
                                    setPaymentsMenuVisible(!isPaymentsMenuVisible);
                                    setGuestsMenuVisible(false);
                                    setSettingsMenuVisible(false);
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        px: 2.5,
                                        color: "#FFFFFF",
                                        flexDirection: "column",
                                    }}
                                >
                                    <img src={PaymentIcon} className="sidebar-icon" alt="" />
                                    <ListItemText primaryTypographyProps={{ variant: "caption" }}>
                                        Payments &nbsp;{" "}
                                        <i
                                            className={`far fa-chevron-${
                                                isPaymentsMenuVisible ? "up" : "down"
                                            }`}
                                        />
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </span>
                        <motion.div
                            initial={false}
                            animate={{
                                height: isPaymentsMenuVisible ? "fit-content" : 0,
                                overflow: isPaymentsMenuVisible ? "clip" : "clip",
                            }}
                        >
                            {PaymentsGroup.map((item) => {
                                const menuItem = getMenuItem(item);
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                            style={{
                                                backgroundColor: "#42C9F8",
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    <menuItem.Icon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </motion.div>

                        {navMenu
                            .filter((item) => !AllGroups.includes(item.title))
                            .filter(
                                (item) =>
                                    item.title === "Discount" ||
                                    item.title === "Support" ||
                                    item.title === "Profile",
                            )
                            .map((menuItem) => {
                                return (
                                    <NavLink to={menuItem.path} style={{ textDecoration: "none" }}>
                                        <ListItem
                                            key={menuItem.title}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            color="secondary"
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                    color:
                                                        window.location.pathname === menuItem.path
                                                            ? "#003580"
                                                            : "white !important",
                                                    backgroundColor:
                                                        window.location.pathname === menuItem.path
                                                            ? "white"
                                                            : "transparent",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        justifyContent: "center",
                                                        color:
                                                            window.location.pathname ===
                                                            menuItem.path
                                                                ? "#003580"
                                                                : "white !important",
                                                    }}
                                                >
                                                    {menuItem.IconType === "iconify" &&
                                                    typeof menuItem.Icon === "string" ? (
                                                        <Icon icon={menuItem.Icon} width="24" />
                                                    ) : (
                                                        <menuItem.Icon />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "caption" }}
                                                    primary={menuItem.title}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </NavLink>
                                );
                            })}

                        {/* <ListItem disablePadding sx={{ display: "block" }} color="secondary" onClick={context.doLogout}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                px: 2.5,
                                color: "#FFFFFF",
                                flexDirection: "column",
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: "center",
                                    color: "#FFFFFF",
                                }}
                            >
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </ListItem> */}
                    </List>
                </Drawer>
            </div>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <AppBar
                    style={{
                        borderBottomColor: "#D9D9D9",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",

                        boxShadow: "none",
                    }}
                    position="fixed"
                    color="secondary"
                    sx={{ position: "initial" }}
                >
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" className="welcome-header">
                            {/* {`Welcome, ${context.admin!.firstName} ${context.admin!.lastName}`} */}
                            <Link to="/">
                                <img src={NavLogo} alt="" className="nav-logo" />
                            </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <span className="flex-row header-admin-box">
                                {`${context.admin!.firstName[0]} ${context.admin!.lastName[0]}`}
                            </span>
                            <Typography className="header-admin-typography">
                                {context.admin!.firstName}
                            </Typography>
                            {/* <Typography>Admin</Typography> */}
                            &nbsp;
                            <span
                                className="toggle-header-menu pointer"
                                onClick={() => {
                                    setHeaderMenuShowing(!isHeaderMenuShowing);
                                }}
                                onMouseEnter={() => {
                                    setHeaderMenuShowing(true);
                                }}
                            >
                                {isHeaderMenuShowing ? (
                                    <i className="far fa-chevron-up toggle-header-menu-icon" />
                                ) : (
                                    <i className="far fa-chevron-down toggle-header-menu-icon" />
                                )}
                            </span>
                            <motion.div
                                initial={false}
                                animate={{
                                    height: isHeaderMenuShowing ? "fit-content" : "0px",
                                }}
                                className={`header-menu-container flex-column ${
                                    isHeaderMenuShowing ? "" : "hide-header-menu-border"
                                }`}
                            >
                                <span
                                    onClick={() => {
                                        navigate("/profile");
                                    }}
                                    className="header-menu-item flex-row pointer"
                                >
                                    Profile
                                </span>
                                <span
                                    className="header-menu-item flex-row pointer"
                                    onClick={() => {
                                        context.doLogout();
                                    }}
                                >
                                    Logout
                                </span>
                            </motion.div>
                        </Box>
                    </Toolbar>
                </AppBar>
                {props.children}
            </Box>
        </Box>
    );
}
export { getIsEmpty };

export enum StorageKeys {
    Token = "quick_stay_admin_token",
    Admin = "quick_stay_admin_admin",
}

export function saveData<P>(key: StorageKeys, data: P, skipStringify?: boolean) {
    try {
        if (skipStringify) {
            localStorage.setItem(key, data as string);
        } else {
            localStorage.setItem(key, JSON.stringify(data));
        }
    } catch {
        // TODO: save to error logger
    }
}

export function retrieveData<P>(key: StorageKeys, skipParse?: boolean): P | null {
    try {
        const rawData = localStorage.getItem(key);

        if (skipParse && rawData) {
            return rawData as P;
        }

        if (rawData) {
            return JSON.parse(rawData) as P;
        }

        return null;
    } catch {
        // TODO: save to error logger
        return null;
    }
}

export function deleteData(key: StorageKeys) {
    try {
        localStorage.removeItem(key);
    } catch {
        // TODO: save to error logger
    }
}
